.App {
  background-color: #000;
  color: #fff;
  background-image: url("/public/bg.png");
  font-size: 18px;
  overflow: hidden;
}

.App .bg-dark{
  background-color: rgba(0, 0, 0, 0.1) !important;
}

nav .container {
  margin-top: 20px;
}

.successfull {
  color: green;
}

nav .container .navbar-collapse a {
  font-size: 20px;
  color: #7fd8eb;
}

.heroTitle{
  font-size: 37px;
  margin: 125px 0 29px 0;
  font-weight: 600;
}

.heroSlogon{
  font-size: 18px;
  margin: 0 0 78px 0;
  font-weight: 400;
}

.heroSlogon span {
  display: block;
  margin-top: 20px;
}

.img-fluid {
  max-width: 70%;
  margin: 35px;
}

.mintBtn {
  margin: -10px 0 45px 0;
}

.mintBtn button {
  font-size: 19px !important;
  padding: 10px 30px !important;
}

a {
  transition: all ease .3s;
  text-decoration: none;
  color: #75c5e1;
}

a:hover {
  transition: all ease .3s;
  color: #3cb0da;
}

.mintBtn button:hover {
  cursor: auto;
  background-color: #308eaf;
}

.App .btn-primary{
  background-color: #75c5e1;
  border-color: #1dc4ff;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 15px 60px;
}

.boxDescription{
  margin: 30px 0 30px 0;
  font-weight: 600;
  font-size: 19px;
}

.parag-eligible {
  margin-top: 20px;
  margin-bottom: 30px;
}

.paragText .p-eligible {
  display: block;
  margin-top: 9px;
}

.paragTitle{
  border-left: 8px solid #75c5e1;
  padding: 0 0 0 15px;
  font-size: 30px;
  font-weight: 600;
  margin: 120px 0 32px 0;
}

.parag{
  margin: 45px 0 60px 0;
}

.footer{
  padding: 50px 0;
  font-size: 16px;
}

.allkeysTitle{
  font-size: 30px;
  font-weight: 600;
  margin: 120px 0 60px 0;
}

.nav-link {
  margin-right: 20px;
}

.keys{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin: 0 0 120px 0;
}

.keyText{
  max-width: 390px;
}

.boldSpan{
  font-weight: 700;
}

.common {
  color: #428ebb;
}

.rare {
  color: #a300a3;
}

.super-rare {
  color: green;
}


.key{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.keyTitle{
  color: #75c5e1;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.boxImage{
  max-width: 600px;
}

.boxImagecontainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 62px;
}

.loadingGift{
  margin: 30px 0 30px 0;
}

@media screen and (max-width: 500px) {
  .heroTitle{
    margin-top: 35px;
    font-size: 28px;
    margin-bottom: 50px;
  }
  .heroSlogon span {
    font-size: 16px;
  }
  .img-fluid {
    max-width: 76%;
    margin-bottom: 0;
  }
  nav .container {
    margin-top: 10px;
    margin-left: 18px;
  }
  .mintBtn button {
    font-size: 16px !important;
  }
  nav img {
    width: 150px;
    height: 37px;
  }
  .boxDescription{
    font-size: 17px;
    padding: 0 20px 0 20px;
  }
  button {
    margin-bottom: 20px;
  }
  .keys {
    margin: 0px 0px 120px -25px;
    font-size: 15px;
  }
  .key2 {
    margin-top: 0;
  }
  .paragText {
    font-size: 16px;
  }
  .key3 {
    margin-top: 0;
  }  
  .keyText {
    padding: 20px;
  }
  .paragTitle {
    margin: 100px 0 32px 0;
  }
  .footer {
    margin-top: -45px;
  }
}